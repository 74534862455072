<template>
  <section>
    <div class="all-media">
      <Teleport to="#header-buttons">
        <div id="tooltip-no-permissions">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            :disabled="loading || !getPermissionOfMember"
            @click="goToCreativeStudio"
          >
            Creative Studio
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            class="ml-2"
            :disabled="loading || !getPermissionOfMember"
            @click="addMediaButton"
          >
            Add Media
            <b-tooltip
              v-if="!getPermissionOfMember"
              target="tooltip-no-permissions"
            >
              <span>You are not authorized for this!</span>
            </b-tooltip>
          </b-button>
        </div>
      </Teleport>

      <b-skeleton-wrapper :loading="loading">
        <template #loading>
          <b-skeleton width="100%" />
          <b-skeleton width="75%" />
          <b-skeleton width="50%" />
        </template>
        <div
          v-if="allMediaStorageData.length === 0"
          class="text-center mt-5 font-large-1 text-black-50"
        >No Media Found</div>
        <b-card
          v-if="allMediaStorageData.length"
          no-body
          class="mt-lg-0 mt-2"
        >
          <div class="m-2">
            <b-row>
              <b-col
                cols="12"
                md="6"
                lg="6"
                sm="12"
                offset-sm="0"
                offset-md="6"
                offset-lg="6"
              >
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input
                    id="searchText"
                    v-model="searchQuery"
                    class="d-inline-block mr-1"
                    placeholder="Search..."
                    @keyup.enter="pageChanged(1)"
                  />
                  <b-button
                    variant="primary"
                    @click="pageChanged(1)"
                  >
                    Search
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </div>
          <b-skeleton-table
            v-if="loading"
            :rows="paginationObject.limit"
            :columns="5"
            :table-props="{ bordered: true, striped: true, tableClass:'skeleton-loading-table' }"
          />
          <b-table
            v-else
            ref="mediaTable"
            :items="allMediaStorageData"
            :per-page="paginationObject.limit"
            hover
            selectable
            :select-mode="selectMode"
            responsive
            :fields="tableColumns"
            class="position-relative"
            @row-selected="onRowSelected"
          >
            <!-- Column: Media -->
            <template #cell(MEDIA)="data">
              <b-media vertical-align="center">
                <template
                  #aside
                >
                  <div>
                    <b-img
                      class="userMediaImage"
                      :src="getThumbnailOfFile(data.item)"
                    />
                  </div>
                </template>
                <span
                  class="text-nowrap text-color-class text-truncate"
                  style="max-width: 160px;"
                >
                  {{ data.item.name }}
                </span>
              </b-media>
            </template>

            <!-- Column: Size -->
            <template #cell(SIZE)="data">
              <span class="text-nowrap">
                {{ Math.ceil(data.item.size) }} Mb
              </span>
            </template>

            <!-- Column: Type -->
            <template #cell(TYPE)="data">
              {{ data.item.type }}
            </template>
            <!-- Column: Imported From -->
            <template
              #cell(DURATION(seconds))="data"
              class="d-flex justify-content-center align-items-center"
            >
              {{ data.item.duration }}
            </template>

            <!-- Column: Actions -->
            <!--          <b-tooltip-->
            <!--            v-if="!getPermissionOfMember"-->
            <!--            triggers="hover"-->
            <!--            target="tooltip-permissions"-->
            <!--          >-->
            <!--            <span>You are not authorized!</span>-->
            <!--          </b-tooltip>-->
            <template #cell(ACTIONS)="data">
              <div
                class="text-nowrap"
              >
                <feather-icon
                  :id="`invoice-row-${data.item.id}-send-icon`"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  icon="TrashIcon"
                  class="cursor-pointer float-right mr-2"
                  size="16"
                  @click="deleteModal(data.item.id, $event)"
                />
                <b-tooltip
                  v-if="!getPermissionOfMember"
                  triggers="hover"
                  :target="`invoice-row-${data.item.id}-send-icon`"
                >
                  <span>You are not authorized for this!</span>
                </b-tooltip>
              </div>
            </template>
          </b-table>
        </b-card>
      </b-skeleton-wrapper>
      <div class="mt-0">
        <div class="text-nowrap">
          <!-- pagination -->
          <CustomPagination
            v-if="allMediaStorageData.length"
            :total="paginationObject.total"
            :per-page="paginationObject.limit"
            :page="paginationObject.offset"
            @perPageChanged="perPageChanged"
            @pageChanged="pageChanged"
          />
        </div>
      </div>
      <AddMedia
        ref="add-media-modal"
        @getAllMediaStorage="getAllMediaStorageOfUser(paginationObject)"
      />
    </div>
  </section>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BImg,
  BTooltip,
  VBToggle,
  VBTooltip,
  BSkeletonTable, BSkeletonWrapper, BSkeleton,
} from 'bootstrap-vue'
import Vue from 'vue'
import Ripple from 'vue-ripple-directive'
import Vuesax from 'vuesax'
import { required } from '@validations'
import 'vuesax/dist/vuesax.css'
// import moment from 'moment'
import Teleport from 'vue2-teleport'
import AddMedia from '@/components/mediastorage/AddMedia.vue'
import MediaType from '@/common/enums/mediaTypeEnum'
import {
  confirmDeletion, getThumbnailOfFile, showErrorMessage, showToast,
} from '@/common/global/functions'
// import store from '@/store'
import MemberPermissions from '@/common/enums/memberPermissionsEnum'
import CustomPagination from '@/components/common/CustomPagination.vue'

Vue.use(Vuesax)
Vue.directive('ripple', Ripple)
export default {
  components: {
    CustomPagination,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BImg,
    BTooltip,
    AddMedia,
    BSkeletonTable,
    BSkeletonWrapper,
    BSkeleton,
    Teleport,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  // filters: {
  //   convertSecToMin(value) {
  //     if (!value) return '--'
  //     return moment.utc(value * 1000).format('HH:mm:ss')
  //   },
  // },
  // beforeRouteEnter(to, from, next) {
  //   const memberPermission = store.getters['user/getPermissionOfMember'](MemberPermissions.MEDIA)
  //   if (!memberPermission) {
  //     next({
  //       name: 'dashboard',
  //     })
  //   } else { next() }
  // },
  data() {
    return {
      MediaType,
      loading: false,
      index: null,
      allMediaStorageData: [],
      option: [{ title: 'Video' }, { title: 'Image' }],
      searchQuery: '',
      tableColumns: [
        { key: 'MEDIA', thClass: 'pull-left text-left' },
        { key: 'SIZE' },
        { key: 'TYPE' },
        { key: 'DURATION(seconds)' },
        { key: 'ACTIONS', thClass: 'pull-right text-right' },
      ],
      selectMode: 'single',
      selected: [],
      paginationObject: {
        offset: 1,
        limit: 10,
        total: 0,
      },
      // validation
      required,
    }
  },
  computed: {
    getPermissionOfMember() {
      return this.$store.getters['user/getPermissionOfMember'](MemberPermissions.MEDIA)
    },
  },
  mounted() {
    this.getAllMediaStorageOfUser(this.paginationObject)
  },
  methods: {
    getThumbnailOfFile(file) {
      return getThumbnailOfFile(file)
    },
    perPageChanged(limit) {
      this.paginationObject.offset = 1
      this.paginationObject.limit = limit
      this.getAllMediaStorageOfUser(this.paginationObject)
    },
    pageChanged(offset) {
      this.paginationObject.offset = offset
      this.getAllMediaStorageOfUser(this.paginationObject)
    },
    async getAllMediaStorageOfUser({ offset, limit }) {
      this.paginationObject = { ...this.paginationObject, offset, limit }
      this.loading = true
      try {
        const res = await this.$axios.get(`media?offset=${offset - 1}&limit=${limit}&search_query=${this.searchQuery}`)
        const { data: { data } } = res || {}
        this.allMediaStorageData = data.results
        this.paginationObject.total = data.total
        this.loading = false
      } catch ({
        response: {
          data: {
            statusCode, message,
          },
        },
      }) {
        if (showErrorMessage(statusCode)) {
          showToast('Media Storage', 'You are not authorized for this action!', 'danger')
          this.loading = false
        }
      } finally {
        this.loading = false
      }
    },
    addMediaButton() {
      this.searchQuery = ''
      this.$refs['add-media-modal'].show()
    },
    async onRowSelected(items) {
      if (items) {
        const mediaArray = Object.assign([], items)
        await this.$store.dispatch('lightBox/lightBoxData', mediaArray)
      }
      await this.$refs.mediaTable.clearSelected()
    },
    deleteModal(id, e) {
      e.preventDefault()
      e.stopPropagation()
      if (this.getPermissionOfMember) {
        confirmDeletion().then(async result => {
          if (result.value) {
            this.successDelete(id)
          }
        })
      }
    },
    async successDelete(id) {
      try {
        await this.$axios.delete(`media/${id}`)
        showToast('Delete Media', 'Media has been deleted successfully!')
        await this.getAllMediaStorageOfUser(this.paginationObject)
      } catch ({
        response: {
          data: {
            statusCode, message,
          },
        },
      }) {
        if (statusCode === 400) {
          showToast('Delete Media', 'Media added in Internal Ad Campaigns or target ad cannot be deleted!', 'danger')
        } else if (showErrorMessage(statusCode)) {
          showToast('Media Storage', message.toString(), 'danger')
        }
      }
    },
    async goToCreativeStudio() {
      this.$router.push('/creative-studio')
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";

.all-media{
.card{
  text-align: center
}
 .media-aside{
   margin-right: 0px
 }
  .media-body{
    display: inherit;
    padding-left: 10px;
  }
}
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
.circular_image {
  border-radius: 40px;
  background-color: #eeedfd;
}
.button{
  width: 300px;
  height: auto;
border-radius: 5px !important;
}
.text-color-class{
  color: #1f58b5 !important;
}
.userMediaImage{
  width: 20px;
  height: auto;
}
.skeleton-loading-table{
  th, td{
    padding: 1rem 2rem !important;
    height: 3.1rem !important;
    .b-skeleton-text{
      height: 1.2rem;
      margin-bottom: 0;
    }
  }
}
</style>
